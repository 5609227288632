if (typeof window !== 'undefined') {
    if (window._tcf_ && window._tcf_.showPrivacyManager) {
        const settingsButton = document
            .querySelector('finn-footer')
            ?.shadowRoot?.querySelector('#footer-tcf-privacy-manger');
        if (settingsButton) {
            settingsButton.addEventListener('click', () => {
                window._tcf_.showPrivacyManager();
            });
            settingsButton.removeAttribute('style');
        }
    }
}
