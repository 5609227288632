const schibstedBannerKey = 'schibsted-banner-last-viewed';
const oneday = 60 * 60 * 24 * 1000;
const now = +new Date();
const stickyClass = ['bottom-0', 'fixed', 'w-full', 'border-solid', 'border', 'border-bluegray-300', 'z-20'];

function isInViewport(elem) {
    const distance = elem.getBoundingClientRect();
    return (
        distance.top >= 0 &&
        distance.left >= 0 &&
        distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        distance.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

function shouldShowBanner() {
    try {
        const lastViewed = parseInt(localStorage.getItem(schibstedBannerKey) || '', 10);
        if (!lastViewed || now - lastViewed > oneday) {
            localStorage.setItem(schibstedBannerKey, now.toString(10));
            return true;
        }
    } catch (e) {
        // Ignore errors from browsers that does not support localStorage.
        if (!(e.message === 'The quota has been exceeded.')) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }
    return false;
}

if (typeof window !== 'undefined') {
    if (shouldShowBanner()) {
        const bannerElement = document
            ?.querySelector('finn-footer')
            ?.shadowRoot?.querySelector('#schibsted-banner');
        if (bannerElement && !isInViewport(bannerElement)) {
            bannerElement.classList.add(...stickyClass);
            setTimeout(() => {
                let match = true;
                for (const stickyClassMember of stickyClass) {
                    if (!bannerElement.classList.contains(stickyClassMember)) {
                        match = false;
                    }
                }
                if (match) {
                    bannerElement.classList.remove(...stickyClass);
                }
            }, 8000);
        }
    }
}
