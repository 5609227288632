export function getBrand(brand) {
    switch (brand) {
        case 'TORI':
            return 'Tori';
        case 'FINN':
            return 'FINN.no';
        case 'DBA':
            return 'DBA.dk';
        default:
            return '';
    }
}

export function getBrandAndCompanyType(brand) {
    switch (brand) {
        case 'TORI':
            return 'Tori';
        case 'FINN':
            return 'FINN.no AS';
        case 'DBA':
            return 'DBA.dk';
        default:
            return '';
    }
}

export function getBrandAndCompanyTagline(brand) {
    switch (brand) {
        case 'TORI':
            return 'Tori';
        case 'FINN':
            return 'FINN.no - mulighetenes marked';
        case 'DBA':
            return 'Dba';
        default:
            return '';
    }
}

export function brandFounded(brand) {
    switch (brand) {
        case 'TORI':
            return '2009';
        case 'FINN':
            return '1996';
        default:
            return '';
    }
}

export function hostToBrand(host) {
    if (host.includes('tori')) {
        return 'TORI';
    }

    if (host.includes('finn')) {
        return 'FINN';
    }

    if (host.includes('dba')) {
        return 'DBA';
    }

    return 'FINN';
}
